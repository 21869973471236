<template>
  <v-card style="margin-top:1em">
    <v-card-text>
      <v-alert v-if="appStatus === 'Denied'" type="error">{{ reason ? reason : 'This request has been denied by Student Life' }}</v-alert>
      <v-alert v-else-if="appStatus === 'Declined'" type="error" outlined>{{ reason ? reason.replace(user.preferred + ' ' + user.last, 'You') : 'This request has been declined by one of the roommates' }}</v-alert>
      <v-alert v-else-if="appStatus === 'Canceled'" type="warning" outlined>{{ reason ? reason.replace(user.preferred + ' ' + user.last, 'You') : 'This request has been canceled' }}</v-alert>
      <v-alert v-else-if="!userHasApproved" type="info">You have been invited to join this housing request. Please click either the Approve or Decline buttons below to respond to this request.</v-alert>
      <v-alert v-else-if="appStatus === 'Pending'" type="info">Your request has been submitted to Student Life and is awaiting approval.</v-alert>
      <v-alert v-else-if="appStatus === 'Returned'" type="warning">{{ reason ? reason : 'Your request has been returned for you to make changes.' }}</v-alert>
      <v-alert v-else-if="appStatus === 'Approved'" type="success">Your housing request has been approved.</v-alert>
      <v-row>
        <v-col v-if="option === 'stay' || option === 'change'" :cols="12" :md="roommates.length === 3 ? 3 : 4">
          <v-card style="height:100%">
            <v-card-text>
              <h4>Phase {{ phase }}</h4>
              <v-text-field :value="room" label="Selected Room" class="no-line" readonly hide-details></v-text-field>
              <v-text-field :value="hall" label="Hall" class="no-line" readonly hide-details></v-text-field>
              <v-text-field :value="building" label="Building" class="no-line" readonly hide-details></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-else-if="option === 'apartment'" :cols="12" :md="roommates.length === 3 ? 3 : 4">
          <v-card style="height:100%">
            <v-card-title style="word-break:normal">Apartment</v-card-title>
            <v-card-text>This is an application to live in an apartment. The apartment number will be assigned if and when this request is approved.</v-card-text>
          </v-card>
        </v-col>
        <v-col v-else-if="option === 'off-campus'">
          <v-card>
            <v-card-title>Applied to live off campus</v-card-title>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Exception Reason</v-list-item-subtitle>
                  <v-list-item-title>{{ offCampus.exception }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Street Address</v-list-item-subtitle>
                  <v-list-item-title>{{ offCampus.address }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>City</v-list-item-subtitle>
                  <v-list-item-title>{{ offCampus.city }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>State</v-list-item-subtitle>
                  <v-list-item-title>{{ offCampus.state }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Zip</v-list-item-subtitle>
                  <v-list-item-title>{{ offCampus.zip }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col v-for="(person, index) in roommates" :key="'roommate-' + index" :cols="12" :sm="6" :md="roommates.length === 3 ? 3 : 4">
          <v-card style="min-height:130px">
            <v-card-text v-if="person.category === 'student'">
              <img :src="person.avatar || '/img/no.jpg'" style="max-height:150px;max-width:120px;width:100%;margin:auto;display:block" />
              <h4 style="text-align:center">{{ person.name }}</h4>
              <div style="width:100%;text-align:center">
                <v-icon v-if="person.approved" color="success" left>fas fa-check-circle</v-icon>
                <v-icon v-else color="info" left>fas fa-exclamation-circle</v-icon>
                {{ person.approved ? 'Confirmed' : 'Pending' }}
              </div>
            </v-card-text>
            <v-card-text v-else-if="person.category === 'applicant'">
              <h4>{{ person.name }}</h4>
              <p>New incoming student</p>
            </v-card-text>
            <v-card-text v-else-if="person.category === 'unknown'">
              <p>No planned roommate</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <h3 style="margin:1em 0 0">Request Status: {{ statusText }}</h3>
    </v-card-text>
    <v-card-actions v-if="(appStatus === 'Proposed' || appStatus === 'Returned') && !userHasApproved">
      <v-dialog v-if="option === 'apartment'" v-model="mealPlanDialog" width="600">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="success" alt="Approve Housing Request">
            <v-icon left>fas fa-check-circle</v-icon>
            Approve
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Optional Meal Plan</v-card-title>
          <v-card-text>
            <p>Meal plan participation is optional for students living in the apartments. If you would like to purchase a meal plan, please select one from the drop-down menu below. Note that your selection will automatically apply to both the Fall and Spring semesters, though the plans reset at the end of each semester with no roll-over. If you desire to purchase a meal plan for the Fall semester only, please make your selection below and then visit the Student Development Office in December to remove the Spring plan from your account. Please reference the College's Tuition & Fees Schedule for meal plan pricing.</p>
            <v-select v-model="mealPlan" :items="mealPlanOptions" label="Meal Plan" outlined hide-details></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" alt="Submit Housing Approval" @click="approve">
              <v-icon left>fas fa-check-circle</v-icon>
              Submit
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="mealPlanDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn v-else color="success" alt="Approve Housing Request" @click="approve">
        <v-icon left>fas fa-check-circle</v-icon>
        Approve
      </v-btn>
      <v-spacer></v-spacer>
      <v-dialog v-model="declineDialog" width="400" persistent>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="error" alt="Decline Housing Request">
            <v-icon left>fas fa-times-circle</v-icon>
            Decline
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Decline Housing Request?</v-card-title>
          <v-card-text>Please confirm that you would like to decline this housing request. This will send an email to each of the students who have approved the request to let them know that you have declined it and that they will need to submit a new housing request.</v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="decline" alt="Confirm Decline Housing Request">
              <v-icon left>fas fa-times-circle</v-icon>
              Confirm: Decline
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="declineDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    <v-card-actions v-else-if="isSubmitter && (appStatus === 'Proposed' || appStatus === 'Pending')">
      <v-dialog v-model="cancelDialog" width="400" persistent>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="info">
            <v-icon left>fas fa-times-circle</v-icon>
            Cancel Request
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Cancel Request</v-card-title>
          <v-card-text>Please confirm that you would like to cancel this housing request. This will send an email to each of the students who have approved the request to let them know that you have cancelled it and that they will need to submit a new housing request.</v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="cancel" alt="Confirm Cancel Housing Request">
              <v-icon left>fas fa-times-circle</v-icon>
              Confirm: Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="cancelDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>
<style>
.v-input.no-line.v-input--is-readonly .v-input__slot::before {
  border-width: 0 !important
}
</style>
<script>
import { computed, ref, watch } from '@vue/composition-api'
export default {
  props: {
    request: {
      type: Object,
      required: true
    },
    current: {
      type: Object,
      default: () => {
        return {
          bldg: '',
          building: '',
          hall: '',
          room: '',
          capacity: 0,
          offCampus: {
            address: '',
            city: '',
            state: '',
            zip: ''
          }
        }
      }
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const startedBy = ref('')
    // Check to see if the current user is the one who submitted this request; we will have different options if they are not the one who submitted it
    const isSubmitter = computed(() => user.value._id === startedBy.value)
    const term = ref('')
    const appStatus = ref('')
    const reason = ref('')
    const statusText = computed(() => {
      switch (appStatus.value) {
        case 'Proposed':
          return 'Waiting on all roommates to approve'
        case 'Declined':
          return 'A roommate declined this request'
        case 'Canceled':
          return 'This request was cancelled'
        case 'Returned':
          return 'This request was returned by Student Life'
        case 'Pending':
          return 'Waiting for approval from Student Life'
        case 'Denied':
          return 'This request was denied by Student Life'
        case 'Approved':
          return 'This request has been approved'
      }
    })
    const phase = ref(0)
    const option = ref('')
    const room = ref('')
    const hall = ref('')
    const building = ref('')
    const roommates = ref([])
    const offCampus = ref({
      exception: '',
      address: '',
      city: '',
      state: '',
      zip: ''
    })
    const userHasApproved = ref(false)
    const userIndex = ref(-1)

    watch(() => props.request, (val) => {
      load(val)
    })

    async function load (data) {
      term.value = data.term
      startedBy.value = data.startedBy
      appStatus.value = data.status
      reason.value = data.reason
      phase.value = data.phase
      option.value = data.option
      room.value = data.room
      hall.value = data.hall
      building.value = data.building
      roommates.value = []
      for (let i = 0; i < data.roommates.length; i++) {
        const roommate = data.roommates[i]
        if (roommate.pidm === user.value.pidm) {
          userHasApproved.value = roommate.approved
          userIndex.value = i
        }
        if (roommate.category === 'student' && roommate.pidm != null && roommate.pidm !== '') {
          const { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm: roommate.pidm } })
          if (dirData.length > 0) {
            roommate.avatar = dirData[0].photo
          }
        }
        roommates.value.push(roommate)
      }
      if (appStatus.value === 'off-campus') {
        for (const field in offCampus.value) {
          if (field in data.offCampus) offCampus.value[field] = data.offCampus[field]
        }
      }
      if ('offCampus' in data && typeof (data.offCampus) === 'object' && 'address' in data.offCampus) {
        for (const field in offCampus.value) {
          offCampus.value[field] = data.offCampus[field]
        }
      }
    }

    async function approve () {
      let patch = { ['roommates.' + userIndex.value + '.approved']: true }
      if (option.value === 'apartment' && mealPlan.value !== '') {
        patch['roommates.' + userIndex.value + '.mealPlan'] = mealPlan.value
      }
      let currentBuilding = (props.current.building === 'Rymer Hall' ? 'Mac' : props.current.building.substring(0, 3))

      // Check to see if the phase number for this student is better than the current phase number
      if (room.value === props.current.room && phase.value > 1) {
        patch.phase = 1
      } else if (hall.value === props.current.hall && phase.value > 2) {
        patch.phase = 2
      } else if (building.value.substring(0, 3) === currentBuilding && phase.value > 3) {
        // Note: we are doing a substring on these because the building names in Banner do not exactly match the building names that we have in the Portal
        patch.phase = 3
      }
      // Find the index number that is this
      const data = await root.$feathers.service('student/housing-request').patch(props.request._id, patch)
      await load(data)
      // Check to see if everyone has now approved; if so, submit the request
      userHasApproved.value = true
      roommates.value.splice(userIndex.value, 1, { ...roommates.value[userIndex.value], approved: true })
      emit('approved')
    }
    const declineDialog = ref(false)
    async function decline () {
      const data = await root.$feathers.service('student/housing-request').patch(props.request._id, { status: 'Declined', reason: user.value.preferred + ' ' + user.value.last + ' declined this proposed housing arrangement.' })
      emit('declined', data)
      declineDialog.value = false
    }
    const cancelDialog = ref(false)
    async function cancel () {
      // Mark the status as rejected with a text that the housing request was cancelled
      const data = await root.$feathers.service('student/housing-request').patch(props.request._id, { status: 'Declined', reason: user.value.preferred + ' ' + user.value.last + ' cancelled this proposed housing arrangement.' })
      emit('declined', data)
      cancelDialog.value = false
    }

    const mealPlanDialog = ref(false)
    const mealPlan = ref('')
    const mealPlanOptions = ref([{ text: 'No Meal Plan', value: '' }, { text: '65 meal plan (per semester)', value: 'MP65' }, { text: '130 meal plan (per semester)', value: 'M130' }, { text: 'Full meal plan', value: 'MFUL' }])

    return {
      user,
      startedBy,
      isSubmitter,
      term,
      appStatus,
      reason,
      statusText,
      phase,
      option,
      room,
      hall,
      building,
      roommates,
      offCampus,
      userHasApproved,
      approve,
      declineDialog,
      decline,
      cancelDialog,
      cancel,
      mealPlanDialog,
      mealPlan,
      mealPlanOptions
    }
  }
}
</script>
